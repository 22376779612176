import React, {Component} from 'react';
import './style.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ButtonPrimary from '../button/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck} from '@fortawesome/free-solid-svg-icons';

type State = {
	nome: string;
	email: string;
	telefone: string;
	empresa: string;
	mensagem: string;
	enviado: boolean;
	error: boolean;
	loading: boolean;
};

class Contato extends Component<Record<string, unknown>, State> {
	constructor(props: Record<string, unknown>) {
		super(props);
		this.state = {
			nome: '',
			email: '',
			telefone: '',
			empresa: '',
			mensagem: '',
			enviado: false,
			loading: false,
			error: false,
		};
	}

	handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {name, value} = event.target;
		this.setState({[name]: value} as unknown as Pick<State, keyof State>);

		const inputElement = event.target;
		const isValueEmpty = inputElement.value.trim() === '';

		if (isValueEmpty) {
			inputElement.classList.remove('is-success');
			inputElement.classList.add('is-danger');
		} else {
			inputElement.classList.add('is-success');
			inputElement.classList.remove('is-danger');
		}
	};

	handlePhoneChange = (value: string) => {
		this.setState({telefone: value});
	};

	handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		this.setState({loading: true});
		const inputs = document.querySelectorAll('.contato__inputText');

		if (!this.state.nome || !this.state.email || !this.state.telefone || !this.state.empresa || !this.state.mensagem) {
			this.setState({error: true});

			inputs.forEach(input => {
				const inputElement = input as HTMLInputElement;
				if (!inputElement.value) {
					input.classList.add('is-danger');
					input.classList.remove('is-success');
				}
			});

			this.setState({loading: false});

			setTimeout(() => {
				this.setState({error: false});
			}, 5000);

			return;
		}

		await fetch('https://ppem2srgti.execute-api.sa-east-1.amazonaws.com/Prod/contato', {
			method: 'POST',
			body: JSON.stringify(this.state),
			headers: {
				'x-api-key': 'Jy1WnhV4C82AVPOflti9P3p3GhyFtBYd6BjogKRD',
				'Content-Type': 'application/json',
			},
		}).then(response => {
			this.setState({
				nome: '',
				email: '',
				telefone: this.state.telefone,
				empresa: '',
				mensagem: '',
				enviado: true,
			});

			setTimeout(() => {
				this.setState({enviado: false});
			}, 5000);
		}).catch(error => {
			this.setState({
				nome: '',
				email: '',
				telefone: this.state.telefone,
				empresa: '',
				mensagem: '',
				error: true,
			});

			setTimeout(() => {
				this.setState({error: false});
			}, 5000);
		});

		this.setState({loading: false});
		inputs.forEach(input => {
			input.classList.remove('is-success');
		});
	};

	render() {
		return (
			<div className='contato'>

				{this.state.enviado && (
					<h2 className='help is-success'>E-mail enviado com sucesso!</h2>
				)}

				{this.state.error && (
					<h2 className='help is-danger'>Falha ao enviar o e-mail</h2>
				)}

				<form onSubmit={this.handleSubmit}>
					<div className='columns is-multiline'>
						<div className='column is-6 mb-4'>
							<label className='label'>Nome</label>
							<div className={`control has-icons-right ${this.state.loading ? 'is-loading' : ''}`}>
								<input
									className='input contato__inputText'
									type='text'
									placeholder='seu nome'
									name='nome'
									value={this.state.nome}
									onChange={this.handleInputChange}
								/>
								{this.state.nome && (
									<span className='icon is-right'>
										<FontAwesomeIcon className='fa-lg' icon={faCircleCheck} />
									</span>
								)}
							</div>
						</div>

						<div className='column is-6 mb-4'>
							<label className='label'>E-mail</label>
							<div className={`control has-icons-right ${this.state.loading ? 'is-loading' : ''}`}>
								<input
									className='input contato__inputText'
									type='email'
									placeholder='exemplo@gmail.com'
									name='email'
									value={this.state.email}
									onChange={this.handleInputChange}
								/>
								{this.state.email && (
									<span className='icon is-right'>
										<FontAwesomeIcon className='fa-lg' icon={faCircleCheck} />
									</span>
								)}
							</div>
						</div>

						<div className='column is-12 mb-4'>
							<label className='label'>Telefone</label>
							<div className='field is-expanded'>
								<PhoneInput
									country='br'
									regions={['america']}
									value={this.state.telefone}
									onChange={this.handlePhoneChange}
									disabled={this.state.loading}
								/>
							</div>
						</div>

						<div className='column is-12 mb-4'>
							<label className='label'>Empresa</label>
							<div className={`control has-icons-right ${this.state.loading ? 'is-loading' : ''}`}>
								<input
									className='input contato__inputText'
									type='text'
									placeholder='Capiware'
									name='empresa'
									value={this.state.empresa}
									onChange={this.handleInputChange}
								/>
								{this.state.empresa && (
									<span className='icon is-right'>
										<FontAwesomeIcon className='fa-lg' icon={faCircleCheck} />
									</span>
								)}
							</div>
						</div>

						<div className='column is-12 mb-4'>
							<label className='label'>Mensagem</label>
							<div className={`control has-icons-right ${this.state.loading ? 'is-loading' : ''}`}>
								<input
									className='input contato__inputText'
									type='text'
									placeholder='Insira aqui sua mensagem'
									name='mensagem'
									value={this.state.mensagem}
									onChange={this.handleInputChange}
								/>
								{this.state.mensagem && (
									<span className='icon is-right'>
										<FontAwesomeIcon className='fa-lg' icon={faCircleCheck} />
									</span>
								)}
							</div>
						</div>

						<div className='column is-12 is-flex is-justify-content-center mb-3'>
							<ButtonPrimary text={'Entre em Contato'} shadow={false} loading={this.state.loading}/>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default Contato;
