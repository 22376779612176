import React, {useState, useEffect} from 'react';
import './style.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';

const WhatsAppButton = () => {
	const [showButton, setShowButton] = useState(true);

	const handleScroll = () => {
		const scrollPosition = window.scrollY + window.innerHeight;
		const documentHeight = document.documentElement.scrollHeight;

		// Ajuste a altura conforme necessário para que o botão pare alguns pixels acima do rodapé
		const offset = 165;

		if (documentHeight - scrollPosition < offset) {
			setShowButton(false);
		} else {
			setShowButton(true);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleWhatsAppClick = () => {
		const whatsappLink = 'https://wa.me/message/EPJJI6O2VPDBF1';
		window.open(whatsappLink, '_blank');
	};

	return (
		<div className={`whatsAppButton ${showButton ? 'show' : 'hide'}`} onClick={handleWhatsAppClick}>
			<FontAwesomeIcon icon={faWhatsapp} size='2xl'/>
		</div>
	);
};

export default WhatsAppButton;
